<template>

    <div>
        <v-toolbar flat >
            <v-btn color="primary mx-2" @click="previousMonth" :disabled="loading">
                <v-icon>mdi-chevron-left</v-icon>

            </v-btn>
            <v-spacer></v-spacer>
            <!-- Selected month in spanish -->
            <span style="text-transform: capitalize">{{ selectedMonth }}</span>
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="nextMonth" class="mx-2" :disabled="loading">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>

        </v-toolbar>

        <v-progress-linear absolute v-if="loading" indeterminate color="primary"></v-progress-linear>

        <v-row no-gutters>
            <!-- Calendar Headers -->
            <v-col v-for="(day, index) in daysOfWeek" :key="index" class="text-center pa-0">
                <v-card class="rounded-0">
                    <v-card-title>{{ day }}</v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <!-- Loop over weeks and group days by week -->
        <v-row no-gutters v-for="(week, weekIndex) in calendarWeeks" :key="weekIndex" align-stretch>
            <v-col v-for="(dayData, index) in week.days" :key="index"  v-bind:class="{ 'selected': dayData == selected }"
                class="calendar-col  fill-height d-flex flex-column" @click="selectDay(dayData, index)">
                <v-card class="calendar-day rounded-0 fill-height pb-12" height="100%" 
                    :class="{ empty: !dayData.date.date(), otherMonth: dayData.isOtherMonth }">
                     <v-toolbar class="day-number px-0" flat dense>

                        <!-- option menu -->
                        <v-menu offset-y offset-x>    
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on">
                                    <v-icon small>
                                        mdi-dots-vertical
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list class="py-0" dense>
                                <v-list-item @click="dayData.title = null" v-if="dayData.title">
                                    <v-list-item-avatar>
                                        <v-icon >mdi-close</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>      
                                        Eliminar título
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-else @click="dayData.title = 'Titulo'">
                                    <v-list-item-avatar>
                                        <v-icon >mdi-pencil

                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>Agregar titulo</v-list-item-title>
                                </v-list-item>
                                    <v-divider/>
<!-- 
                                  <v-list-item  @click="toggleRest()">
                                    <v-list-item-avatar>
                                        <v-icon >mdi-sleep

                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>
                                        Dia de descanso
                                    </v-list-item-title>
                                </v-list-item>

                                


 <v-divider/> -->
                                  <v-list-item @click="showDayReferences(dayData, index)">
                                    <v-list-item-avatar>
                                        <v-icon >mdi-plus</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>Calentamiento / Enfriamiento</v-list-item-title>
                                </v-list-item>




                                <template v-if="dayData.exercises.length > 0">
                                    <v-divider/>
                                <v-list-item @click="dayData.exercises = []" >
                                    <v-list-item-avatar>
                                        <v-icon >mdi-broom</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>Limpiar ejercicios</v-list-item-title>
                                </v-list-item>

                                </template>




                            </v-list>

                        </v-menu>


                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="copyElems(dayData.exercises)" v-show="dayData.exercises.length > 0">
                                    <v-icon small>
                                        mdi-content-copy
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Copiar contenido</span>
                        </v-tooltip>

                        <v-tooltip top v-show="copy">
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="pasteElems(dayData)" v-show="copy">
                                    <v-icon small>
                                        mdi-content-paste
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Pegar contenido</span>
                        </v-tooltip>


                        <v-tooltip top v-if="selectedExercises.length>0">
                            <template v-slot:activator="{ on }">



                                <v-badge color="error" overlap :content="selectedExercises.length" :value="selectedExercises.length" v-show="dayData==selected" >

                               
                                <v-btn icon small v-on="on" v-show="dayData==selected" @click="createClusterFromSelected(dayData)">
                                    <v-icon small>
                                        mdi-plus-box
                                    </v-icon>
                                </v-btn>
                                 </v-badge>
                            </template>
                            <span>
                              {{selectedExercises.length==0 ?'Selecciona ejercicios para crear un superset' : 'Crear superset/grupo con seleccionados'}} 
                            </span>
                        </v-tooltip>

                        <v-spacer></v-spacer>

                        {{ dayData.date.date() }}
                    </v-toolbar>
                    
                     <v-toolbar v-show="dayData.title !=null" class="px-0 toolbar-title"  flat dense style="padding-left:0px !important">
                       <v-text-field label="Titulo"   v-model="dayData.title" dense outlined single-line hide-details=""
                       ></v-text-field>

                    </v-toolbar>

                    <v-card v-show="dayData.isRestDay && dayData.exercises.length==0 && !moving" elevation="0" class="rounded-0">
                        <v-card-text class="text-center">
                            <v-icon large>mdi-sleep</v-icon>
                            <p class="caption">Día de descanso</p>
                        </v-card-text>
                    </v-card>
                    <!-- Always Show v-list (Exercises sorted with draggable) -->

                   

                            <v-subheader class="px-2" v-if="dayData != selected && dayData.warmup && typeof dayData.warmup =='string'">
                                <v-avatar size="5" :color="listRoutines.find(r => r.id === dayData.warmup)?.color" class="mr-2"></v-avatar>

                                {{
                                    listRoutines.find(r => r.id === dayData.warmup)?.name
                                }}
                            </v-subheader>

                           <v-select v-else-if="dayData==selected || typeof dayData.warmup =='string'" 
                             v-model="dayData.warmup" v-show="dayData.warmup && typeof dayData.warmup =='string'" 
                        :items="listRoutines"
                        label="Calentamiento"
                        item-text="name"
                        item-value="id"
                        dense
                        filled
                        rounded
                        @change="dayData.isRestDay=false"
                        class="mt-0 rounded-0"
                        hide-details=""

                    >

                         <template v-slot:append>
                       <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="dayData.warmup = null" v-show="dayData.warmup">
                                <v-icon small>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar seleccionado</span>
                    </v-tooltip>
                    </template>



                     <template v-slot:item="{ item }">
                                                            <v-list-item-content class="px-1">
                                                                <v-list-item-title>
                                                                    <v-avatar size="5"
                                                                        :color="item.color">

                                                                    </v-avatar>
                                                                    {{ item.name }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>



                                                             <template v-slot:selection="data">
                                                            <v-list-item-content class="px-0">
                                                                <v-list-item-title>
                                                                    <v-avatar size="5"
                                                                        :color="data.item.color">

                                                                    </v-avatar>
                                                                    {{ data.item.name }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>

             
                    </v-select>
                    
                    <v-divider v-if="dayData != selected && dayData.warmup && typeof dayData.warmup =='string'" />


                    <v-list dense class="py-0">
                        <draggable v-model="dayData.exercises" group="exercises" handle=".drag-handle"   @end="end"  @start="start"   :move="onMoveDayExercises"

                            v-bind:class="{ 'dragging': moving }"  >
                        
                            <v-list-item v-for="(exercise, exIndex) in dayData.exercises" :key="exIndex" @click="toggleSelect(exIndex)" :class="{ 
                                'selected-item': isSelected(exIndex) && dayData==selected,
                                'px-1': !exercise.isCluster,
                                }" class="drag-handle px-0">
                                
                                <template v-if="exercise.isCluster">
                                    <!-- Render cluster -->
                                    <div class="cluster-container">
                                          <v-divider v-if="exIndex>0" />
                                        <v-list-item class="px-2">
                                           
                                            <v-list-item-content>
                                                <v-list-item-title style="font-weight: bold;text-transform:uppercase">
                                                    <template v-if="exercise.title">
                                                        {{abecedario[exIndex]}}) {{ exercise.title }}
                                                    </template>
                                                    <template v-else>
                                                        {{ abecedario[exIndex] }}) Superset
                                                    </template>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{exercise.sets}} {{ exercise.sets == 1 ?'Vuelta': 'Vueltas' }} {{ exercise.rest? exercise.rest + `'' Descanso` : '' }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-toolbar-items v-if="dayData == selected">
                                                <v-btn icon small @click.stop="removeExercise(dayData, exIndex)">
                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                </v-btn>

                                                <v-menu offset-y :close-on-content-click="false" max-width="500">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" text small icon>
                                                            <v-icon x-small size="15">mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <!-- Menu content for editing cluster -->
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-text-field label="Título" v-model="exercise.title" dense outlined></v-text-field>
                                                            <v-text-field label="Sets" v-model="exercise.sets" type="number" min="1" step="1" dense outlined></v-text-field>
                                                            <v-text-field label="Descanso (segundos)" v-model="exercise.rest" type="number" min="1" step="1" dense outlined></v-text-field>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-menu>


                                            </v-toolbar-items>
                                        </v-list-item>

                                        <v-divider/>

                                        <!-- Display exercises inside the cluster -->
                                        <v-list dense class="cluster-exercise-list py-0">
                                            <draggable v-model="exercise.exercises" group="exercises" handle=".drag-handle-cluster"  v-bind:class="{ 'dragging': moving }"  @end="end"  @start="start" >
                                                <v-list-item v-for="(subExercise, subExIndex) in exercise.exercises" :key="subExIndex" class="clustered-exercise">
                                                
                                                    <v-list-item-content class="pa-0 py-1">
                                                        <v-list-item-title>
                                                             
                                                                <v-autocomplete style="width:200px" :rules="[v => !!v || 'Item is required']"  :prefix="(abecedario[exIndex])+(subExIndex + 1)+')'"
                                                                 class="caption" v-model="subExercise.id" :items="exercises" dense  single-line 
                                                    item-text="name" item-value="id" hide-details  v-if="selected == dayData"></v-autocomplete> 


                                            <template v-else>
                                                 {{abecedario[exIndex]}}{{ subExIndex + 1 }}) {{ exerciseHashIdNames[subExercise.id] }}
                                            </template>
                                                             
                                                             
                                                             
                                                             </v-list-item-title>
                                                        <v-list-item-subtitle>

                                                            {{ subExercise.sets }} x
                                                            {{ subExercise.type === 'reps' ? subExercise.value + ' Rep' : subExercise.value + `'' Trabajo` }} 

                                                            {{ subExercise.rest ? ' x ' + subExercise.rest + `'' Descanso` : '' }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-toolbar-items v-if="dayData ==selected">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" icon small @click.stop="removeClusterExercise(exIndex, subExIndex, dayData)">
                                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Eliminar</span>
                                                        </v-tooltip>

                                                        <v-menu offset-y :close-on-content-click="false" max-width="500">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" text small icon>
                                                                    <v-icon x-small size="15">mdi-pencil</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <!-- Menu content for editing sub-exercise -->
                                                            <v-card>
                                                                <v-card-text>
                                                                    <v-select  v-model="subExercise.type" :items="exerciseTypes" label="Tipo" dense outlined></v-select>
                                                                    <v-text-field v-model="subExercise.value" :label="subExercise.type === 'reps' ? 'Repeticiones' : 'Segundos'" type="number" min="1" step="1" dense outlined></v-text-field>
                                                                    <v-text-field label="Descanso (segundos)" v-model="subExercise.rest" type="number" min="1" step="1" dense outlined></v-text-field>

                                                                </v-card-text>
                                                            </v-card>
                                                        </v-menu>

                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon small class="drag-handle-cluster" style="cursor:pointer;" v-on="on">
                                                                    <v-icon x-small>mdi-cursor-move</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Mover</span>
                                                        </v-tooltip>

                                                     

                                                
                                                    </v-toolbar-items>
                                                </v-list-item>
                                            </draggable>
                                        </v-list>
                                    </div>
                                </template>

                                <template v-else>
                                    <!-- Render regular exercise -->
                                 
                                    <v-list-item-content  v-bind:class="{'py-1': selected == dayData}">
                                        <v-list-item-title style="font-weight: bold;text-transform:uppercase">


                                             <v-autocomplete style="width:200px" class="caption" v-model="exercise.id" :items="exercises" dense  single-line :prefix="abecedario[exIndex]+')'"
                                                    item-text="name" item-value="id" hide-details  v-if="selected == dayData"></v-autocomplete> 


                                            <template v-else>
                                                {{ abecedario[exIndex] }}) {{ exerciseHashIdNames[exercise.id] }}
                                            </template>

                                          
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ exercise.sets }} x {{ exercise.type === 'reps' ? exercise.value + ' Rep' : exercise.value + ` 90'' Trabajo` }}

                                            {{ exercise.rest ? ' x ' + exercise.rest + ` descanso` : '' }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-toolbar-items v-if="dayData == selected">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon small @click.stop="removeExercise(dayData, exIndex)" v-on="on">
                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>

                                        <v-menu offset-y :close-on-content-click="false" max-width="500">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" text small icon>
                                                    <v-icon x-small size="15">mdi-pencil</v-icon>
                                                </v-btn>
                                            </template>
                                            <!-- Menu content for editing exercise -->
                                            <v-card>
                                                <v-card-text>
                                                    <v-select v-model="exercise.type" :items="exerciseTypes" label="Tipo" dense outlined></v-select>
                                                    <v-text-field label="Sets" v-model="exercise.sets" type="number" min="1" step="1" dense outlined></v-text-field>
                                                    <v-text-field v-model="exercise.value" :label="exercise.type === 'reps' ? 'Repeticiones' : 'Segundos'" type="number" min="1" step="1" dense outlined></v-text-field>
                                                    <v-text-field label="Descanso (segundos)" v-model="exercise.rest" type="number" min="1" step="1" dense outlined></v-text-field>
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </v-toolbar-items>
                                </template>
                            </v-list-item>
                        </draggable>

                        <!-- Option to add new exercise -->


                       
                    </v-list>


                
                                    <v-menu :close-on-content-click="false" :offset-x="true" v-if="dayData == selected">
                                    <template v-slot:activator="{ on: menuOn }">
                                        <v-btn style="text-transform:capitalize" color="primary" elevation="0" class="rounded-0" block large v-on="{ ...menuOn }" v-show="dayData == selected"  >
                                        <v-icon left>
                                            mdi-plus
                                        </v-icon>
                                        ejercicio
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-text>
                                        <v-select v-model="newExerciseType" :items="exerciseTypes" label="Tipo" dense outlined></v-select>
                                        <v-row>
                                            <v-col cols="6">
                                            <v-text-field label="Sets" v-model="newExerciseSets" type="number" min="1" step="1" dense outlined></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                            <v-text-field v-model="newExerciseValue" :label="newExerciseType === 'reps' ? 'Repeticiones' : 'Segundos'" type="number" min="1" step="1" dense outlined></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-autocomplete v-model="selectedExercise" :items="exercises" hide-details hide-selected label="Seleccionar ejercicio" item-text="name" item-value="id" dense outlined @change="addExercise(dayData)"></v-autocomplete>
                                        </v-card-text>
                                    </v-card>
                                    </v-menu>

                            <v-divider v-if="dayData != selected && dayData.cooldown && typeof dayData.cooldown =='string'"/>

                          <v-subheader v-if="dayData != selected && dayData.cooldown && typeof dayData.cooldown =='string'" class="px-2" >
                            <v-avatar size="5" :color="listRoutines.find(r => r.id === dayData.cooldown)?.color" class="mr-2"></v-avatar>

                            {{
                                listRoutines.find(r => r.id === dayData.cooldown)?.name
                            }}
                        </v-subheader>
                             


                    <v-autocomplete v-else-if="dayData==selected || typeof dayData.cooldown =='string'"
                        v-model="dayData.cooldown" v-show="dayData.cooldown && typeof dayData.cooldown =='string'"
                        :items="listRoutines"
                        label="Enfriamiento"
                        item-text="name"
                        item-value="id"
                        dense
                        filled
                        rounded
                        @change="dayData.isRestDay=false"
                        class="mt-0 rounded-0"
                            hide-details=""
                            style="min-width:200px"

                    >
                     <template v-slot:append>
                       <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="dayData.cooldown = null" v-show="dayData.cooldown">
                                <v-icon small>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar seleccionado</span>
                    </v-tooltip>
                    </template>
                     <template v-slot:item="{ item }">
                                                            <v-list-item-content class="px-1">
                                                                <v-list-item-title>
                                                                    <v-avatar size="5"
                                                                        :color="item.color">

                                                                    </v-avatar>
                                                                    {{ item.name }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>



                                                             <template v-slot:selection="data">
                                                            <v-list-item-content class="px-0">
                                                                <v-list-item-title>
                                                                    <v-avatar size="5"
                                                                        :color="data.item.color">

                                                                    </v-avatar>
                                                                    {{ data.item.name }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                    </v-autocomplete>


                    <v-tooltip bottom v-show="dayData == selected">
                                    <template v-slot:activator="{ on }" >
                                          <v-btn block class="rounded-0" elevation="0"  v-on="on" large style="text-transform: capitalize;position: absolute;
    bottom: 0;" @click="openAssistant">
                                            <v-icon left small>
                                                mdi-robot-happy
                                            </v-icon>
                                            Asistente
                                        </v-btn>
                                    </template>
                                    <span>Generar con Asistente</span>
                                </v-tooltip>   


                </v-card>
            </v-col>
        </v-row>


        <Builder @confirmWorkout="confirmGeneratedWorkout" ref="asistantBuilder" />



        <v-dialog v-model="dialogReferences" width="500">
            <v-card>
                <v-card-title>
                    Referencias

                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogReferences = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="selected" class="pa-4">
                    <v-autocomplete dense
                        v-model="dayData.warmup"
                        :items="listRoutines"
                        label="Seleccionar calentamiento"
                        item-text="name"
                        item-value="id"
                        rounded
                        filled 
                        class="rounded-lg"
                                               @change="dayData.isRestDay=false"

                    >
                    <template v-slot:append>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="dayData.warmup = null" v-show="dayData.warmup">
                                    <v-icon small>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar seleccionado</span>
                        </v-tooltip>
                    </template>
                    
                    </v-autocomplete>

                    <v-autocomplete
                        v-model="dayData.cooldown"
                        :items="listRoutines"
                        label="Seleccionar enfriamiento"
                        item-text="name"
                        item-value="id"
                        rounded
                        filled 
                        class="rounded-lg"
                        @change="dayData.isRestDay=false"
                    >

                    <template v-slot:append>
                       <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="dayData.cooldown = null" v-show="dayData.cooldown">
                                <v-icon small>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar seleccionado</span>
                    </v-tooltip>
                    </template>
                    </v-autocomplete>
                </v-card-text>
                                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="hideReferencesDialog"> Volver</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { 
    getFirestore, 
    collection,
    getDoc, 
    getDocs, 
    doc, 
    setDoc, 
    deleteDoc,
    where,
    query

} from 'firebase/firestore';
import moment from 'moment';
import Builder from './Builder.vue';

export default {
    props:{
          exercises: {
            type: Array,
            required: false,
            default: () => [],
        },

        listRoutines: {
            type: Array,
            required: false,
            default: () => [],
        },
        program: {
            type: Object,
            required: false,
            default: () => {},
        },

    },
    components: {
        draggable, Builder
    },
    data() {
        return {
            loading: false,
            ejerciciosOpen: true,
            daysOfWeek: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
            calendarWeeks: [],
            selectedExercise: null,
            newExerciseSets: 1,
            newExerciseType: 'reps',
            newExerciseValue: 10,
            selectedMonth: moment().locale('es').format('MMMM YYYY'),
            months: Array.from({ length: 12 }, (v, i) => {
                const date = moment().month(i).locale('es');
                return { text: date.format('MMMM YYYY'), value: date };
            }),
            currentDate: moment().locale('es'),


            exerciseTypes: [
                { text: 'Repeticiones', value: 'reps' },
                { text: 'Tiempo', value: 'time' },

            ],


            exerciseHashIdNames: {},

            copy: null,
            selectedExercises: [],
            selected: null,
            dayData: {},
            dialogReferences: false,
            moving: false,

            abecedario: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],

        };
    },
    mounted() {
        this.generateCalendarDays();
        this.getWorkouts();

    },
    methods: {

        /* 
        This functions searches in 'planifications/'+ this.$program.id + '/workouts' collection for the workouts of the current displaying days generated with this.generateCalendarDays(),
        Then updates the calendarDays array data with the workouts data if there is any workout for that day.
        Document id must be the date in format 'YYYY-MM-DD'.
        Use query to get all the documents at once between the actual view dates.
        
        */
      
        async getWorkouts() {
             this.loading=true;
             const db = getFirestore();

            const dummyRef = doc(db, `planifications/${this.program.id}/workouts`, 'dummy');
            const workoutsRef = collection(db, `planifications/${this.program.id}/workouts`);

            //check if exists
            const docSnap = await getDoc(dummyRef);
            if (!docSnap.exists()) {
                const dummyDocRef = doc(workoutsRef, "dummy");
                await setDoc(dummyDocRef, { createdAt: new Date() });
                console.log("Document written with ID: ", dummyDocRef.id);
            }

            const startOfMonth = this.calendarWeeks[0].days[0].date.toDate();
            const endOfMonth =  this.calendarWeeks[this.calendarWeeks.length - 1].days[this.calendarWeeks[this.calendarWeeks.length - 1].days.length - 1].date.toDate();
            const q = query(workoutsRef, where('date', '>=', startOfMonth), where('date', '<=', endOfMonth));
            const querySnapshot = await getDocs(q);

            // match the workouts with the calendar days, remember date is Date object
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const date = moment(data.date.toDate());
                
                this.calendarWeeks.forEach((week,index) => {
                    week.days.forEach((day,index2) => {
                        if (day.date && day.date.isSame(date, 'day')) {
                            this.$set(this.calendarWeeks[index].days[index2], 'exercises', data.exercises);
                            this.$set(this.calendarWeeks[index].days[index2], 'warmup', data.warmup);
                            this.$set(this.calendarWeeks[index].days[index2], 'cooldown', data.cooldown);
                            this.$set(this.calendarWeeks[index].days[index2], 'title', data.title);
                        }
                    });
                });


            });

            console.log('finished',querySnapshot.size)

            this.loading=false;
        },

        /* 
        This function saves the current workout data to the corresponding workouts collection in the database,
        Empty days must be deleted from the database to keep consistency with the calendarDays array data.
        Use promise.all to wait for all the queries to finish before updating the calendarDays array.
        */
        async saveWorkout() {
            const db = getFirestore();
            const workoutsRef = collection(db, `planifications/${this.program.id}/workouts`);
            const savePromises = this.calendarWeeks.flatMap(week => 
            week.days.map(day => {
                if (day.date) {
                const docId = day.date.format('YYYY-MM-DD');
                    if (day.exercises.length > 0 || day.warmup || day.cooldown || day.title) {
                        console.log('saving', docId);

                        const workoutData = {
                            ...day,
                            date: day.date.toDate(),
                        };

                        return setDoc(doc(workoutsRef, docId), workoutData, { merge: true });
                    } else {
                        console.log('deleting', docId);
                        return deleteDoc(doc(workoutsRef, docId));
                    }
                }
                return Promise.resolve();
            })
            );

            await Promise.all(savePromises);

            console.log('saved');
        },

         set(value){
            console.log(value);
        },
        async get(){
            await this.saveWorkout();
            return [];
        },
        showDayReferences(dayData,index){
            this.selectDay(dayData, index)
            //select the day to show the references

            this.$nextTick(()=>{

                this.dialogReferences=true;
            })
        },
        createClusterFromSelected(dayData) {
            if (this.selectedExercises.length > 0) {
                const selectedExercisesData = this.selectedExercises.map(index => dayData.exercises[index]);
                const newCluster = {
                    isCluster: true,
                    title: null,
                    sets: 1,
                    exercises: selectedExercisesData,
                };

                dayData.exercises.push(newCluster);

                dayData.exercises = dayData.exercises.filter((_, index) => !this.selectedExercises.includes(index));
                this.selectedExercises = [];
            }
        },
         toggleSelect(index) {
            
            try{
                  // If the exercise is a cluster, we don't want to select it
            if (this.selected.exercises[index].isCluster) return;

            if (this.selectedExercises.includes(index)) {
                this.selectedExercises = this.selectedExercises.filter(i => i !== index);
            } else {
                this.selectedExercises.push(index);
            }

            }catch(e){
                console.log(e);
            }
          
            
        },
        onMoveDayExercises(evt) {


        let toDivElements = evt.to
        //check if a children element has class clustered-exercise
        let isClustered = Array.from(toDivElements.children).some((el) => el.classList.contains("clustered-exercise"));

        let draggedItem = evt.draggedContext.element;

        if (isClustered && draggedItem.isCluster) {
            console.warn("Cannot move exercises inside a cluster.");
            return false; // Block exercises from being moved inside clusters
        }


           


     


     

    return true; // Allow other moves
  },

  // Allow only exercises inside cluster's exercises lists
  onMoveClusterExercises(evt) {
    if (this.draggedItem?.isCluster) {
      console.warn("Clusters cannot be nested.");
      return false; // Block clusters from cluster lists
    }

    return true; // Allow exercises to be dropped
  },
         hideReferencesDialog(){
            this.dialogReferences = false;
        },
        confirmGeneratedWorkout(exerciseList){
            //this must concatenate the exercises of the selected day with the exercises generated by the assistant
            this.selected.exercises = this.selected.exercises.concat(exerciseList);
            
            this.selected.isRestDay = false;
        },
         async openAssistant(){
            this.$refs.asistantBuilder.toggleDialog();
        },
         selectDay(dayData) {
            this.moving = false;

            if(this.selected === dayData) return;

            this.selected = dayData;
            this.selectedExercise = null;
            this.newExerciseSets = 1;
            this.newExerciseType = 'reps';
            this.newExerciseValue = 10;
            this.selectedExercises = [];

            this.dayData = dayData;
        },
         addExercise(dayData) {
            if (this.selectedExercise) {
                const selectedExerciseData = this.exercises.find(ex => ex.id === this.selectedExercise);

                if (selectedExerciseData) {
                    const newExercise = {
                        id: selectedExerciseData.id,
                        sets: this.newExerciseSets,
                        type: this.newExerciseType,
                        value: this.newExerciseValue,
                    };

                    dayData.exercises.push(newExercise);

                    this.selectedExercise = null;
                    this.newExerciseSets = 1;
                    this.newExerciseType = 'reps';
                    this.newExerciseValue = 10;

                     this.selected.isRestDay = false;
                }

            }

           
        },
         isSelected(index) {
            return this.selectedExercises.includes(index);
        },
        generateCalendarDays() {
            const startOfMonth = this.currentDate.clone().startOf('month');
            const endOfMonth = this.currentDate.clone().endOf('month');
            const startDay = startOfMonth.startOf('isoWeek');
            const endDay = endOfMonth.endOf('isoWeek');

            let calendar = [];
            for (let date = startDay.clone(); date.isBefore(endDay); date.add(1, 'day')) {
                calendar.push({
                    date: date.clone(),
                    isOtherMonth: !date.isSame(this.currentDate, 'month'),
                    exercises: [],
                    warmup: null,
                    cooldown: null,
                    notes: [],
                    form: [],
                    isRestDay: false,
                    title: null,
                });
            }

            // Split calendar days into weeks
            this.calendarWeeks = calendar.reduce((weeks, day, index) => {
                const weekIndex = Math.floor(index / 7);
                if (!weeks[weekIndex]) weeks[weekIndex] = { days: [] };
                weeks[weekIndex].days.push(day);
                return weeks;
            }, []);
        },
        async previousMonth() {
            this.loading=true;
            this.saveWorkout().then(() => {
                this.currentDate = this.currentDate.subtract(1, 'month');
                this.selectedMonth = this.currentDate.format('MMMM YYYY');
                this.generateCalendarDays();
                this.getWorkouts();
            });

          
        },
        async nextMonth() {
            this.loading=true;

            this.saveWorkout().then(() => {
                this.currentDate = this.currentDate.add(1, 'month');
                this.selectedMonth = this.currentDate.format('MMMM YYYY');
                this.generateCalendarDays();
                this.getWorkouts();
            });
        },
        changeMonth() {
            this.currentDate = moment(this.selectedMonth, 'MMMM YYYY', 'es');
            this.generateCalendarDays();
        },
        copyElems(exercises) {
            this.copy = JSON.parse(JSON.stringify(exercises));
        },
        pasteElems(dayData) {
            if (this.copy) {
                this.copy.forEach(exercise => {
                    dayData.exercises.push(exercise);
                });

                this.copy = null;
            }
        },
        cloneDog({ id }) {
            return {
                id: id,
                type: null,
                sets: 1,
                value: 10

            };
        },
          end(evt){
           this.moving = false;
        },
        start(evt){
            this.moving = true;
        },
    },
    watch: {
        exercises: {
            handler() {
                this.exerciseHashIdNames = this.exercises.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {});
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>


.calendar-day {
    min-height: 100%;
    background-color: #f4f4f4;
}

.calendar-day.otherMonth {
    background-color: #e0e0e0;
}

.theme--dark .calendar-day {
    background-color: #333;
}

.theme--dark .calendar-day.otherMonth {
    background-color: #444;
}
</style>


<style scoped>
.calendar-col {
    padding: 0px;
/*     min-width: 14.2857%;
 */    height: auto;
/*     transition: width 0.5s ease;
 */}

/* selected calendar col and not selected, selected must be 20% with and the other 6 the percentage left */
.calendar-col.selected {
    width: 20% !important;
}

.calendar-col:not(.selected) {
    width: 6% !important;
}

.calendar-day {
    min-height: 200px
}

.selected-item {
    background-color: #e0f7fa;
}

.cluster-container {
    width: 100%;
}

.clustered-exercise {
    margin-bottom: 5px;
    padding-right: 0px;
    padding-left:8px !important
}

.drag-handle {
    cursor: move;
}

.theme--dark .selected-item {
    background-color: rgba(255, 255, 255, 0.2);
}

.theme--light .selected-item {
    background-color: rgba(0, 0, 0, 0.2);
}

.cluster-container {
    width: 100%;
    border-left: 2px solid var(--v-primary-base);
}

.cluster-container-warm {
    width: 100%;
    border-left: unset
}

.cluster-container-cooldown {
    width: 100%;
    border-left: unset
}


.dragging{
    outline: 1px dashed #42a5f5;
    min-height:60px;
}

.drag-handle-column{
    cursor: move;
}

.drag-handle-cluster{
    cursor: move !important;
}



</style>
<style>

.v-input.caption{
    margin:0px !important;
}

.v-input.caption input {
    text-overflow: ellipsis;
    padding: 0px !important;
    max-height: 20px !important;
}


.toolbar-title > .v-toolbar__content {
  padding:2px !important;
}
</style>